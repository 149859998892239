import Button from "react-bootstrap/Button";
import { Col, Row, Toast } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { apiPost } from "../utility/japi";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();
  const [responseMsg, setResponseMsg] = useState("");
  const [changeTosterColor, setchangeTosterColor] = useState("");
  const [mobNumber, setMobNumber] = useState("");
  const [chanracterCount, setCharacterCount] = useState(200);
  const navigate = useNavigate();

  const onSubmit = (data) => {
    apiPost(data).then((res) => {
      if (res.status == 200) {
        setResponseMsg(
          "Thanks for your message, we will connect with you soon."
        );
        setchangeTosterColor("success");
        setTimeout(() => {
          setResponseMsg("");
          navigate("/");
        }, 5000);
      } else {
        setResponseMsg("Oops! Something went wrong, please try again later.");
        setchangeTosterColor("error");
        setTimeout(() => {
          setResponseMsg("");
        }, 5000);
      }
    });
  };

  const enterNumberOnly = (event) => {
    let value = event.target.value;
    const regex = /^[0-9+\-()]*$/;
    if (regex.test(value)) {
      setMobNumber(value);
      clearErrors("phoneNumber");
    }
  };

  const textAreaValidation = (event) => {
    let value = event.target.value;
    console.log("value", value.length);
    if (value.length > 200) {
      setCharacterCount(200 - value.length);
    } else {
      setCharacterCount(200 - value.length);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center flex-column contact-us py-3">
        {/* <h2>Contact Us</h2> */}
        {/* <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Name <span className="astrick">*</span>
                  </Form.Label>
                  <Form.Control
                    id="name"
                    {...register("name", { required: "Name is required" })}
                    placeholder="Your name"
                    className={`${errors.name ? "error-input" : ""}`}
                    maxLength={40}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Organization <span className="astrick">*</span>
                  </Form.Label>
                  <Form.Control
                    id="organization"
                    {...register("organization", {
                      required: "Name is required",
                    })}
                    placeholder="Organization name"
                    className={`${errors.organization ? "error-input" : ""}`}
                    maxLength={40}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Phone Number <span className="astrick">*</span>
                  </Form.Label>
                  <Form.Control
                    id="phoneNumber"
                    {...register("phoneNumber", {
                      required: "Phone Number is required",
                    })}
                    placeholder="Enter phone number"
                    className={`${errors.phoneNumber ? "error-input" : ""}`}
                    onChange={enterNumberOnly}
                    value={mobNumber}
                    maxLength={20}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Email <span className="astrick">*</span>
                  </Form.Label>
                  <Form.Control
                    id="email"
                    type="email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                        message: "Invalid email address",
                      },
                    })}
                    placeholder="Enter your email"
                    className={`${errors.email ? "error-input" : ""}`}
                    maxLength={100}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    style={{ height: "100px" }}
                    id="message"
                    {...register("message", {
                      required: false,
                    })}
                    placeholder="Enter your message"
                    className="textarea-field"
                    maxLength={200}
                    onChange={textAreaValidation}
                    onPaste={textAreaValidation}
                  />

                  <div className="d-flex justify-content-between p-1">
                    <span></span>
                    <span className="character-count error-fields">
                      {chanracterCount}/200 characters
                    </span>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <Button variant="primary" className="submit-btn w-25" type="submit">
              Submit
            </Button>
          </div>
        </form> */}
        <iframe
          className="rounded-3"
          width="610px"
          height="800px"
          src="https://in.bigin.online/org60025090271/forms/contact-us"
        ></iframe>
        {responseMsg && (
          <div className={`toster ${changeTosterColor}`}>{responseMsg}</div>
        )}
      </div>
    </>
  );
};
export default ContactUs;
